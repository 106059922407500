<template>
    <div class="h-full flex flex-col pb-0">

        <div class="flex flex-1 justify-between overflow-auto">
            <div class="w-full">
               <div class="flex justify-between">
                   <div>
                       <p class="font-bold">unit number:</p>
                       <p>prefilled</p>
                   </div>
                    <div>
                       <p class="font-bold">move-in date:</p>
                       <p>prefilled</p>
                   </div>
                    <div>
                       <p class="font-bold">lease end date:</p>
                       <p>prefilled</p>
                   </div>
                   <div>
                      <p class="font-bold">current rent:</p>
                      <p>prefilled</p>
                   </div>

               </div>
               <div class="flex justify-between mt-4">
                   <div class="w-5/12">
                       <p class="font-bold">resident (prefilled):</p>
                       <div class="flex justify-between border my-2 p-1 bg-gray-100">
                           <p>first name last name</p>  <icon name="times" class="w-6 h-6" />
                       </div>
                       <div class="flex justify-between border my-2 p-1 bg-gray-100">
                           <p>first name last name</p>  <icon name="times" class="w-6 h-6" />
                       </div>
                       <div class="flex justify-between border my-2 p-1 bg-gray-100">
                           <p>first name last name</p>  <icon name="times" class="w-6 h-6" />
                       </div>
                   </div>
                   <div class="w-5/12">
                        <div class="flex justify-between mt-2">
                            <p class="font-bold">extend renewal offer?</p>
                            <toggle-input  @input="removeOffers" v-model="isExtendRenewal"/>
                        </div>
                        <div v-if="!isExtendRenewal">
                            <label class="font-bold" for="reasonForNO">reason for No:</label>
                            <Dropdown
                                id="reasonForNO"
                                :options="reasonsForNoList"
                                v-model="reasonsForNo"
                                objectMode
                            />
                        </div>
                        <div class="flex justify-between mt-2">
                          <p class="font-bold">require application?</p>
                          <toggle-input v-model="isRequiredApplication"/>
                        </div>
                        <div class="form-row w-full mt-4">
                          <button
                            class="transcript text-gray-500 focus:outline-none font-semibold py-2 px-4 border button-color"
                            v-bind:class="{ activeCatg: currentTab == 1 }"
                            @click="selectTab(1)">
                            new lease
                          </button>
                          <button
                            class="mismatchtext-gray-500 focus:outline-none font-semibold py-2 px-4 border button-color"
                            v-bind:class="{ activeCatg: currentTab == 2 }"
                            @click="selectTab(2)">
                            addendum
                          </button>
                        </div>
                   </div>
               </div>
               <div v-if="isExtendRenewal" class="p-2 mt-2 border-2">
                   <div
                    v-for="(offer, i) in offers"
                    :key="i"
                    class="flex justify-between mt-2">

                        <div>
                            <label for="">renewal offer amount</label>
                            <input
                            type="text"
                            class="form-input p-0"
                            id="insuranceCarrierName"
                            />
                        </div>

                        <p class="mx-4 py-8">for</p>

                        <div>
                            <label  for="">term</label>
                            <input
                            type="text"
                            class="form-input p-0"
                            id="insuranceCarrierName"
                            />
                        </div>
                        <button
                            class="text-red-700 focus:outline-none mr-1"
                            type="button"
                            v-on:click="removeOffer(i)"
                        >
                            <icon name="trash"  class="w-8 h-8 mt-2 ml-2 text-red-500" />
                        </button>
                   </div>
                   <button
                        class="flex items-center font-bold focus:outline-none button-color"
                        type="button"
                        v-on:click="addOffer()"
                        v-if="this.offers.length < 6"
                    >
                    <icon name="plus" class="w-5 h-5 color-text" />
                    <p v-if="this.offers.length === 0">add new offer</p>
                    <p v-else>add another offer</p>

                    </button>
               </div>
               <div class="mt-4">
                    <p class="font-bold">
                        additional information to include in the offer letter:
                    </p>
                    <rich-editor v-model="content" :disabled="false"/>
               </div>
               <div class="flex justify-between mt-1">
                  <div class="mr-4">
                      <label for="">month-to-month rent</label>
                      <input
                      type="text"
                      class="form-input p-0"
                      id="insuranceCarrierName"
                      />
                  </div>
                  <div>
                      <label for="">month-to-month fee</label>
                      <input
                      type="text"
                      class="form-input p-0"
                      id="insuranceCarrierName"
                      />
                  </div>
               </div>
            </div>

        </div>
        <modal-footer :footer="footer" :primary="save" :secondary="cancel"/>

    </div>
</template>
<script>
import AuthMixin from '@/components/auth/AuthMixin'
import Icon from '@/components/ui/Icon'
import RichEditor from '@/components/move_in/RichEditor'
import ToggleInput from "@/components/ui/ToggleInput";
import Dropdown from '@/components/ui/Dropdown';
import ModalFooter from '@/components/ui/modals/ModalFooter'
import ModalNavigation from '@/mixins/ModalNavigation'

export default {
    name: 'EditRenewalOffer',
    components:{
      Icon,
      ToggleInput,
      Dropdown,
      RichEditor,
      ModalFooter,
    },
    mixins:[AuthMixin, ModalNavigation],
    data() {
        return {
            content: "<p>Some initial content</p>",
            offers: [1],
            isExtendRenewal: true,
            isRequiredApplication: true,
            reasonsForNoList: [
                {
                    key:0,
                    value:'Lease Violations'
                },
                  {
                    key:1,
                    value:'Late payments'
                },
                  {
                    key:2,
                    value:'Background/Criminal Check'
                },
                  {
                    key:3,
                    value:'Community Renovations'
                },
                  {
                    key:4,
                    value:'Other'
                },
            ],
            reasonsForNo: [],
            footer: {
                primaryButton: 'generate offer',
                secondaryButton: 'cancel',
            },
            chatId: "0cc0fdc0-5b0a-4187-9046-5df4d8dfa561",
            currentTab: 1
        }
    },
    methods: {
        addOffer() {
            this.offers.push('')
        },
        removeOffer(i) {
            this.offers.splice(i, 1)
        },
        removeOffers() {
            this.offers = []
        },
        save(){
            this.$router.push({name: 'bookmarks'});
        },
        cancel(){
            this.$router.push({name: 'renewals.index'});
        },
        selectTab(nextTab) {
          if (this.currentTab !== nextTab) {
            this.currentTab = nextTab;
          }
        },
    },
}
</script>

<style scoped>
  .active {
    color: var(--highlightColor500);
    border-bottom: 2px solid;
    border-color: var(--highlightColor500);
    margin: 0 5px 0 5px;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
</style>
